import React from 'react';
import styled from 'styled-components';
import media from 'css-in-js-media';
import { useClearRefinements } from 'react-instantsearch';

const ClearRefinementsButton = styled.button`
    display: flex;
    padding: 12px 30px;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-radius: 6px;
    background: #F6F6F6;
    color: #9D9D9D;
    text-align: center;
    font-family: var(--light-body-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.5px;
    border: none;
    cursor: pointer;
    outline: none;

    ${media('>tablet')} {
        display: inline-block;
        padding: 12px 25px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 6px;
        background: rgba(246, 246, 246, 1);
        color: rgba(157, 157, 157, 1);
        font-family: var(--light-body-font-family);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        letter-spacing: -0.5px;    
        border: none;
        cursor: pointer;
        text-decoration: none;
        outline: none;
        margin-bottom: 24px;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: var(--colour-turquoise);
            color: var(--colour-black);
        }
    }
`;

const ClearRefinements = (props) => {
    const { canRefine, refine } = useClearRefinements(props);

    return (
        <ClearRefinementsButton disabled={!canRefine} onClick={refine}>
            Clear filters
        </ClearRefinementsButton>
    );
}

export default ClearRefinements;