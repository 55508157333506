import React from 'react';
import styled from 'styled-components';
import { useRefinementList } from 'react-instantsearch';

const RefinementListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RefinementListTitle = styled.h3`
  color: var(--colour-black);
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.5px;
`;

const RefinementListList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RefinementListItem = styled.li`
  margin: 0px;
  padding: 0px;
  line-height: 16px;
`;

const RefinementListButton = styled.button`
  display: inline-flex;
  padding: 0px;
  margin: 0px;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: var(--colour-black);
  font-family: var(--light-body-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: -0.5px;
  transition: color 0.3s ease-in-out;

  ${props =>
    props.checked &&
    `
        color: var(--colour-turquoise);
    `}

  i {
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 0.75px solid #d7d7d7;
    background: var(--colour-white);
    transition: all 0.2s ease-in-out;

    ${props =>
      props.checked &&
      `
        border: 5px solid var(--colour-turquoise);
    `}
  }

  &:hover {
    color: var(--colour-turquoise);
  }
`;

const RefinementList = props => {
  const { items, refine } = useRefinementList(props);

  return (
    <RefinementListContainer>
      <RefinementListTitle>Filter by content type</RefinementListTitle>
      <RefinementListList>
        {items.map(item => (
          <RefinementListItem key={item.label}>
            <RefinementListButton checked={item.isRefined} onClick={() => refine(item.value)}>
              <i></i>
              {item.label}
            </RefinementListButton>
          </RefinementListItem>
        ))}
      </RefinementListList>
    </RefinementListContainer>
  );
};

export default RefinementList;
