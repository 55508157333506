import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PageSEO from '../components/PageSEO';
import LayoutContainer from '../components/LayoutContainer';
import { LocalizedSEO } from '../fragments';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import InsightsHero from '../components/ui/InsightsHero';
import SearchBox from '../components/ui/algolia/SearchBox';
import SearchLayout from '../components/ui/algolia/SearchLayout';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const routing = {
  stateMapping: simple(),
};

interface QueryData {
  sanityInsightsPage: {
    seo: LocalizedSEO;
  };
}

const InsightsPage = (): React.ReactElement => {
  const data: QueryData = useStaticQuery(
    graphql`
      {
        sanityInsightsPage {
          seo {
            ...SEO
          }
        }
      }
    `,
  );

  const {
    sanityInsightsPage,
  } = data;

  return (
    <LayoutContainer
      pageTitle={'News and insights'}
      headerBackground={'transparent'}
      showLetsTalkBanner={true}
    >
      <PageSEO pageSEO={sanityInsightsPage.seo} defaultTitle="News and insights" />
      <InstantSearch searchClient={searchClient} indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME} routing={routing}>
        <InsightsHero />
        <SearchBox />
        <SearchLayout />
      </InstantSearch>

    </LayoutContainer>
  );
};

export default InsightsPage;