import React from 'react';
import styled from 'styled-components';
import { useCurrentRefinements } from 'react-instantsearch';

const CurrentRefinementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const CurrentRefinementsTitle = styled.h3`
  color: var(--colour-black);
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.5px;
  margin-bottom: 0px;
`;

const CurrentRefinementsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CurrentRefinementsListItem = styled.li`
  margin: 0px;
  padding: 0px;
`;

const CurrentRefinementsButton = styled.button`
    display: inline-flex;
    padding: 12px 15px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 6px;
    background: var(--colour-turquoise);
    color: var(--colour-black);
    text-align: center;
    font-family: var(--light-body-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.5px;
    border: none;
    cursor: pointer;
`;

const CurrentRefinements = props => {
  const { items, refine } = useCurrentRefinements(props);

  return (
    <CurrentRefinementsContainer>
      <CurrentRefinementsTitle>Selected</CurrentRefinementsTitle>
      <CurrentRefinementsList>
        {items.map(item =>
          item.refinements.map(refinement => (
            <CurrentRefinementsListItem key={[item.indexName, item.label].join('/')}>
              <CurrentRefinementsButton
                type="button"
                key={refinement.label}
                onClick={event => {
                  if (isModifierClick(event)) {
                    return;
                  }

                  refine(refinement);
                }}
              >
                {refinement.label}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    d="M1.5 8.5L8.5 1.5"
                    stroke="#000F14"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                  <path
                    d="M8.5 8.5L1.5 1.5"
                    stroke="#000F14"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
              </CurrentRefinementsButton>
            </CurrentRefinementsListItem>
          )),
        )}
      </CurrentRefinementsList>
    </CurrentRefinementsContainer>
  );
};

function isModifierClick(event) {
  const isMiddleClick = event.button === 1;

  return Boolean(isMiddleClick || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey);
}

export default CurrentRefinements;
