import React, { useState } from 'react';
import {
  useSearchBox,
  UseSearchBoxProps
} from 'react-instantsearch';
import styled from 'styled-components';
import searchboxicon from '../../../images/UI/search-box-icon.svg';

const SearchBox = (props: UseSearchBoxProps) => {
  const { query, refine } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);

  function setQuery(newQuery: string) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  const SearchBoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1167px;
    margin: -40px auto 0px auto;
    padding: 0px 32px;
  `;

  const SearchBoxInput = styled.input`
    display: flex;
    width: 100%;
    height: 80px;
    padding: 0px 48px 0px 52px;
    align-items: center;
    flex-shrink: 0;
    color: var(--colour-black);
    font-family: var(--light-body-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */    
    background: url(${searchboxicon}) no-repeat 20px 50%;
    border:none;
    background-color:white;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    outline: none; 
    &:focus {
        outline: none;
    }
  `;

  return (
    <SearchBoxContainer>
        <SearchBoxInput
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="What do you want to search for?..."
          spellCheck={false}
          maxLength={512}
          type="search"
          value={inputValue}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
          autoFocus
        />
    </SearchBoxContainer>
  );
}

export default SearchBox;