import React from 'react';
import styled from 'styled-components';
import Hits from './Hits';
import Pagination from './Pagination';

const SearchResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

const SearchResultsTitle = styled.h2`
    color: var(--colour-black);
    font-family: var(--light-body-font-family);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 100% */
    letter-spacing: -0.5px;
    margin: 0px;
`;

const SearchResults = () => {
    return (
        <SearchResultsContainer>
            <SearchResultsTitle>Insights & resources</SearchResultsTitle>
            <Hits />
            <Pagination />
        </SearchResultsContainer>
    );
}

export default SearchResults;