import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'css-in-js-media';
import SearchFilters from './SearchFilters';
import SearchFiltersDrawer from './SearchFiltersDrawer';
import SearchResults from './SearchResults';

const SearchLayoutContainer = styled.div`
  display: flex;
  gap: 40px;
  max-width: 1331px;
  margin: 0px auto;
  padding: 10px 32px 45px 32px;
  flex-direction: column;

  ${media('>tablet')} {
    padding: 105px 32px;
    flex-direction: row;
  }
`;

const SearchFiltersDrawerButton = styled.button`
display: flex;
width: 100%;
padding: 20px 6px;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 4px;
color: var(--colour-black);
font-family: var(--light-body-font-family);
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 100% */
letter-spacing: -0.5px;
border-radius: 6px;
background: var(--colour-turquoise);
border: none;
cursor: pointer;
outline: none;

  ${media('>tablet')} {
    display: none;
  }
`;

const SearchLayout = () => {
  const [isSearchFiltersDrawerOpen, setIsSearchFiltersDrawerOpen] = useState(false);

  return (
    <SearchLayoutContainer>
      <SearchFilters />
      <SearchFiltersDrawerButton onClick={() => setIsSearchFiltersDrawerOpen(true)}>
        Filters
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
        >
          <path
            d="M6.5 1.5V11.5"
            stroke="#000F14"
            stroke-width="2"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
          <path
            d="M1.5 6.5H11.5"
            stroke="#000F14"
            stroke-width="2"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
        </svg>
      </SearchFiltersDrawerButton>
      <SearchFiltersDrawer
        isOpen={isSearchFiltersDrawerOpen}
        setIsOpen={setIsSearchFiltersDrawerOpen}
      />
      <SearchResults />
    </SearchLayoutContainer>
  );
};

export default SearchLayout;
