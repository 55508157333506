import React from 'react';
import styled from 'styled-components';
import media from 'css-in-js-media';
import { usePagination } from 'react-instantsearch';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  ${media('>tablet')} {
    margin-top: 80px;
  }
`;

const PaginationList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const PaginationListItem = styled.li`
  margin: 0px;
`;

const PaginationLink = styled.a`
    display: block;
    width: 38px;
    height: 40px;
    background-color: #FFFFFF;
    text-decoration: none;
    color: #9D9D9D;
    text-align: center;
    font-family: var(--light-body-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: -0.5px;
    padding: 0px 2px;
    border-top: 2px solid transparent;

    &:hover {
        color: var(--colour-black);
    }

    ${props => props.isActive && `
        border-top: 2px solid var(--colour-turquoise);
        color: var(--colour-black);
        font-weight: 600;
    `}
`;

const Pagination = props => {
  const { pages, refine, createURL, currentRefinement } = usePagination(props);

  return (
    <PaginationContainer>
      <PaginationList>
        {pages.map(page => {
          const label = page + 1;

          return (
            <PaginationItem
              key={page}
              aria-label={`Page ${label}`}
              href={createURL(page)}
              onClick={() => refine(page)}
              isActive={currentRefinement === page}
            >
              {label}
            </PaginationItem>
          );
        })}
      </PaginationList>
    </PaginationContainer>
  );
};

const PaginationItem = ({ isActive, href, onClick, ...props }) => {
  return (
    <PaginationListItem>
      <PaginationLink
        href={href}
        onClick={event => {
          if (isModifierClick(event)) {
            return;
          }

          event.preventDefault();

          onClick(event);
        }}
        isActive={isActive}
        disabled={isActive}
        {...props}
      />
    </PaginationListItem>
  );
};

function isModifierClick(event) {
  const isMiddleClick = event.button === 1;

  return Boolean(isMiddleClick || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey);
}

export default Pagination;
