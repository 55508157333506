import React from 'react';
import styled from 'styled-components';
import media from 'css-in-js-media';
import { useHits } from 'react-instantsearch';
import { Link } from 'gatsby';

const HitsList = styled.ol`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    list-style: none;
    padding: 0;
    margin: 0;

    ${media('>tablet')} {
        grid-template-columns: repeat(3, 1fr);
        gap: 40px 26px;
    }
`;

const Hit = styled.li`
    margin: 0px;
`;

const HitLink = styled(Link)`
    display: block;
    text-decoration: none;
    color: var(--colour-black);
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: var(--colour-turquoise);

        img {
            transform: scale(1.1);
        }
    }
`;

const HitImageContainer = styled.div`
    aspect-ratio: 3 / 2;
    overflow: hidden;
    margin-bottom: 18px;
`;

const HitImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform-origin: center;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
`;

const HitCategory = styled.span`
    color: #9D9D9D;
    font-family: var(--light-body-font-family);
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px; /* 100% */
    letter-spacing: 1px;
    display: block;
    text-transform: uppercase;
    margin-bottom: 12px;
`;

const HitTitle = styled.h3`
    font-family: var(--light-body-font-family);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 116.667% */
    margin: 0px;
    padding-right: 20px;
`;

const Hits = (props) => {
  const { hits } = useHits(props);

  return (
    <HitsList>
      {hits.map((hit) => (
        <Hit
          key={hit.objectID}
        >
          <HitLink to={hit.slug?.current}>
            <HitImageContainer>
                <HitImage src={hit.image?.asset?.url} alt={hit.title} />
            </HitImageContainer>
            <HitCategory>{hit.mainCategory?.title}</HitCategory>
            <HitTitle>{hit.title}</HitTitle>
          </HitLink>
        </Hit>
      ))}
    </HitsList>
  );
}

export default Hits;