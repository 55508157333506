import React from 'react';
import styled from 'styled-components';
import ClearRefinements from './ClearRefinements';
import CurrentRefinements from './CurrentRefinements';
import RefinementList from './RefinementList';

const SearchFiltersDrawerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: ${(props) => (props.isOpen ? '0px' : '-100vw')};
  background: var(--colour-white);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  transition: right 0.3s ease-in-out;

  @media (min-width: 768px) {
    display: block;
  }
`;

const SearchFiltersDrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 56px 25px 15px 25px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const SearchFiltersDrawerHeaderCloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

const SearchFiltersTitle = styled.h2`
  color: var(--colour-black);
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: -0.5px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SearchFiltersDrawerBody = styled.div`
  padding: 40px 25px 0px 25px;
`;

const SearchFiltersDivider = styled.div`
  height: 1px;
  background: rgba(157, 157, 157, 1);
  margin: 30px 0px;
  opacity: 0.2;
`;

const SearchFiltersDrawerFooter = styled.div`
  padding: 25px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

const SeeResultsButton = styled.button`
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-radius: 6px;
  background: var(--colour-turquoise);
  border: none;
  cursor: pointer;
  outline: none;
  color: var(--colour-black);
  text-align: center;
  font-family: var(--light-body-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.5px;
`;

const SearchFiltersDrawer = ({ isOpen, setIsOpen }) => {
  return (
    <SearchFiltersDrawerContainer isOpen={isOpen}>
      <SearchFiltersDrawerHeader>
        <SearchFiltersTitle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="11"
            viewBox="0 0 8 11"
            fill="none"
          >
            <path
              d="M6 9L2 5.62963L6 2"
              stroke="#000F14"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
          Filters
        </SearchFiltersTitle>
        <SearchFiltersDrawerHeaderCloseButton onClick={() => setIsOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path d="M3 19.5L19 3.5" stroke="#000F14" stroke-width="2" stroke-linecap="square" />
            <path d="M19 19.5L3 3.5" stroke="#000F14" stroke-width="2" stroke-linecap="square" />
          </svg>
        </SearchFiltersDrawerHeaderCloseButton>
      </SearchFiltersDrawerHeader>
      <SearchFiltersDrawerBody>
        <CurrentRefinements />
        <SearchFiltersDivider />
        <RefinementList attribute="categories.title" />
      </SearchFiltersDrawerBody>
      <SearchFiltersDrawerFooter>
        <ClearRefinements />
        <SeeResultsButton onClick={() => setIsOpen(false)}>
            See results
        </SeeResultsButton>
      </SearchFiltersDrawerFooter>
    </SearchFiltersDrawerContainer>
  );
};

export default SearchFiltersDrawer;
