import React from 'react';
import styled from 'styled-components';
import ClearRefinements from './ClearRefinements';
import CurrentRefinements from './CurrentRefinements';
import RefinementList from './RefinementList';

const SearchFiltersContainer = styled.div`
    width: 232px;
    min-width: 232px;
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
`;

const SearchFiltersTitle = styled.h2`
    color: var(--colour-black);
    font-family: var(--light-body-font-family);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 100% */
    margin-bottom: 40px;
`;

const SearchFiltersDivider = styled.div`
    height: 1px;
    background: rgba(157, 157, 157, 1);
    margin: 30px 0px;
    opacity: 0.2;
`;

const SearchFilters = () => {
    return (
        <SearchFiltersContainer>
            <SearchFiltersTitle>Filters</SearchFiltersTitle>
            <ClearRefinements />
            <CurrentRefinements />
            <SearchFiltersDivider />
            <RefinementList attribute="categories.title" />
        </SearchFiltersContainer>
    );
}

export default SearchFilters;